<template>
  <div>
    <el-form
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="">
        <el-col :span="6">
          <label for="cascader">所在地区</label>
          <el-cascader
            v-model="valueCascader"
            class="elcascader"
            :options="options"
            clearable
          ></el-cascader>
        </el-col>
      </el-form-item>

      <el-form-item label="" prop="cardType">
        <el-col :span="6">
          <label for="cardType"
            ><span style="color:red">*</span> 擅长牌型</label
          >
          <el-input
            id="cardType"
            type="textarea"
            maxlength="100"
            :rows="3"
            v-model="dataForm.cardType"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="" prop="selfIntroduction">
        <el-col :span="6">
          <label for="selfIntroduction">
            <span style="color:red">*</span> 自我介绍</label
          >
          <el-input
            type="textarea"
            :rows="3"
            maxlength="100"
            show-word-limit
            v-model="dataForm.selfIntroduction"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="" prop="experience">
        <el-col :span="6">
          <label for="experience"
            ><span style="color:red">*</span>
            对狼人杀游戏/比赛/裁判工作的理解或者心得</label
          >
          <el-input
            type="textarea"
            maxlength="200"
            show-word-limit
            :rows="3"
            v-model="dataForm.experience"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="" prop="referee">
        <el-col :span="6">
          <label for="referee"
            ><span style="color:red">*</span>
            为什么想要成为官方认证裁判？</label
          >
          <el-input
            type="textarea"
            maxlength="200"
            show-word-limit
            :rows="3"
            v-model="dataForm.referee"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="" prop="intend">
        <el-col :span="6">
          <label for="intend"
            ><span style="color:red">*</span>
            成为官方认证裁判后，你有什么打算？</label
          >
          <el-input
            type="textarea"
            maxlength="200"
            show-word-limit
            :rows="3"
            v-model="dataForm.intend"
          ></el-input>
        </el-col>
      </el-form-item>

      <!-- 不校验的 -->
      <el-form-item label="" style="margin-bottom: 35px">
        <el-col :span="6">
          <label for="competitionExperience">您以往的比赛经历</label>
          <el-input
            id="competitionExperience"
            type="textarea"
            maxlength="100"
            show-word-limit
            :rows="3"
            v-model="dataForm.competitionExperience"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="" style="margin-bottom: 35px">
        <el-col :span="6">
          <label for="judgeExperience">您以往的法官经历</label>
          <el-input
            id="judgeExperience"
            type="textarea"
            maxlength="100"
            show-word-limit
            :rows="3"
            v-model="dataForm.judgeExperience"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="" style="margin-bottom: 35px">
        <el-col :span="6">
          <label for="refereeExperience">您以往的裁判经历</label>
          <el-input
            id="refereeExperience"
            maxlength="100"
            type="textarea"
            show-word-limit
            :rows="3"
            v-model="dataForm.refereeExperience"
          ></el-input>
        </el-col>
      </el-form-item>

      <!-- 提交 -->
      <el-form-item style="margin-top: 50px;">
        <perButton
          type="primary"
          perm="armory:sect:save"
          @click="submitForm"
          size="large"
          >提交</perButton
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { cityList } from '../../armory/sect/city'
import perButton from '@/components/perm/perButton'
import { mapActions } from 'vuex'
export default {
  name: 'sect_manager',
  components: {
    perButton,
  },
  props: {
    sect_data: {
      type: Object,
      default: () => ({}),
    },
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function() {
      if (this.view_type == 'edit') {
        return true
      }
      return false
    },
  },
  data() {
    return {
      id_id: '',
      uid: '',
      name: '',
      valueCascader: [], //所在地区
      options: cityList,
      dataForm: {
        cardType: '', //擅长牌型
        selfIntroduction: '', //  自我介绍
        experience: '', // 心得
        referee: '', //裁判
        intend: '', // 打算
        competitionExperience: '', //   比赛经历
        judgeExperience: '', // 法官经历
        refereeExperience: '', //裁判经历
        cityDetail: {
          province: '',
          city: '',
          area: '',
        },
      },
      rules: {
        cardType: [
          { required: true, message: '请输入擅长牌型', trigger: 'blur' },
        ],
        selfIntroduction: [
          { required: true, message: '请输入自我介绍', trigger: 'blur' },
        ],
        experience: [
          {
            required: true,
            message: '请输入对狼人杀游戏/比赛/裁判工作的理解或者心得',
            trigger: 'blur',
          },
        ],
        referee: [
          {
            required: true,
            message: '为什么想要成为官方认证裁判？',
            trigger: 'blur',
          },
        ],
        intend: [
          {
            required: true,
            message: '成为官方认证裁判后，你有什么打算？',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  watch: {
    valueCascader(newValue, oldValue) {
      console.log('用户选择的地区值：', newValue)
    },
  },
  created() {
    this.uid = this.$route.query.uid
    this.name = this.$route.query.uid
    this.id_id = this.$route.query._id
    this.dataEcho()
  },
  methods: {
    findLabelByValue(data, value) {
      if (!data) return null // 添加这行来检查data是否为undefined
      for (let i = 0; i < data.length; i++) {
        if (data[i].value === value) {
          return data[i].label
        }
        if (data[i].children) {
          const label = this.findLabelByValue(data[i].children, value)
          if (label) return label
        }
      }
      return null // 如果没有找到，返回null
    },
    findValueByLabel(data, label) {
      if (!data) return null // 添加这行来检查data是否为undefined
      for (let i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          return data[i].value
        }
        if (data[i].children) {
          const value = this.findValueByLabel(data[i].children, label)
          if (value) return value
        }
      }
      return null // 如果没有找到，返回null
    },
    // 回显
    async dataEcho() {
      const res = await this.$http.armory.refereeDetail({
        _id: this.id_id,
      })
      console.log('🚀 ~ dataEcho ~ res:', res)
      if (res && res.data) {
        this.dataForm.selfIntroduction = res.data.self_introduction
        this.dataForm.cardType = res.data.card_type
        this.dataForm.experience = res.data.q_a[0]
        this.dataForm.referee = res.data?.q_a[1]
        this.dataForm.intend = res.data?.q_a[2]
        this.dataForm.competitionExperience = res.data?.q_a[3]
        this.dataForm.judgeExperience = res.data?.q_a[4]
        this.dataForm.refereeExperience = res.data?.q_a[5]
      }
       if ( res && res.data && res.data.city) {
          const a = this.findValueByLabel(
            this.options,
            res.data.city.province
          )
          const b = this.findValueByLabel(this.options,res.data.city.city)
          const c = this.findValueByLabel(this.options, res.data.city.area)
          console.log('🚀 ~ setFormData ~  this.valueCascader:', a, b, c)
          this.valueCascader = [a, b, c]
        }
    },
    async submitData() {
      // 提交表单数据的逻辑
     if (
        this.valueCascader[0] &&
        this.valueCascader[1] &&
        this.valueCascader[2]
      ) {
        this.dataForm.cityDetail.province = this.findLabelByValue(
          this.options,
          this.valueCascader?.[0]
        )

        this.dataForm.cityDetail.city = this.findLabelByValue(
          this.options,
          this.valueCascader?.[1]
        )
        this.dataForm.cityDetail.area = this.findLabelByValue(
          this.options,
          this.valueCascader?.[2]
        )
      }
        console.log("🚀 ~ submitData ~ this.dataForm.cityDetail.province:", this.dataForm.cityDetail)
      let str = `${this.dataForm.experience},${this.dataForm.referee},${this.dataForm.intend},${this.dataForm.competitionExperience},${this.dataForm.judgeExperience},${this.dataForm.refereeExperience}`
      let arr = str.split(',')
      const res = await this.$http.armory.officialsave({
        status:1,
        _id: this.id_id,
        uid: this.uid,
        name: this.name,
        city: this.dataForm.cityDetail,
        card_type: this.dataForm.cardType,
        self_introduction: this.dataForm.selfIntroduction,
        q_a: arr,
      })
      if (res.code === 0) {
        this.$message.success('编辑成功')
      }
    },
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          // 如果验证通过，执行提交逻辑
          this.submitData()
        } else {
          // 如果验证不通过，可以在这里处理，比如提示用户
          console.log('表单验证未通过')
          return false
        }
      })
    },

    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
}
</script>

<style src="../../editor.css"></style>
<style lang="less" scoped>
.elcascader {
  width: 410px;
  height: 30px;
}
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 400px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 178px;
  height: 178px;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniu1 .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value='custom-color'] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value='custom-color']::before {
  content: '自定义颜色';
  color: black;
  width: fit-content;
}
</style>
